import { Image } from 'antd';
import  card1  from '../../images/card1.webp';
import  card2  from '../../images/card2.webp';

const OplataSection = () => {
	return (
		<div className='text-white text-center mt-20'>

			<h3 className='xz:text-3xl sd:text-5xl uppercase text-[#2db9fb]'>
				Способы оплаты
			</h3>

			<p className='sd:text-2xl xz:text-lg'>
				Наличный и Безналичный расчёт (без НДС)
			</p>


			<p className='uppercase mt-20 mb-6 xz:text-2xl sd:text-4xl'>
				Карты рассрочки
			</p>
			<div className='sd:flex xz:flex-row justify-between items-center'>
				<div className='sd:mx-2 xz:mx-0 sd:mb-0 xz:mb-12'>
					<p className='uppercase'>
						черепаха
					</p>
					<p className=''>
						(на 8 месяцев)
					</p>
					<Image src={card2} preview={false} alt='Оплата за ремонт авто картой рассрочки' />
				</div>

				<div className='sd:mx-2 xz:mx-0 sd:mt-0 xz:mt-12'>
					<p className='uppercase'>
					карта покупок
					</p>
					<p className=''>
					(на 2 месяца)
					</p>
					<Image src={card1} preview={false}  alt='Оплата за ремонт авто картой рассрочки' />
				</div>
			</div>

		</div>
	)
}

export default OplataSection;
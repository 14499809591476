import React from 'react'

const ContenPriceSection = () => {
	return (
		<div className='bg-white font-light py-12'>
			<div className='container px-1'>
				<h4 className='xz:text-xl sd:text-2xl font-bold'>
					Немного про ремонт автомобиля на СТО
				</h4>
				<p className=''>
					Дорогие владельцы автомобилей, давайте поговорим о вашем верном спутнике на дорогах и о том, как наш автосервис может сделать вашу автомобильную жизнь более безопасной, комфортной и долговечной. Мы предлагаем широкий спектр услуг в сфере автомобильного обслуживания, и в этом тексте мы расскажем вам о ключевых преимуществах каждой из них.
				</p>
				<h5 className='font-semibold'>
					Диагностика: Обнаружение и Устранение проблем до того, как они станут критическими
				</h5>
				<p className=''>
					Начнем с основы - диагностики. Наши высококвалифицированные специалисты оборудованы современными средствами диагностики, которые позволяют выявить даже мельчайшие неисправности в вашем автомобиле. Раннее обнаружение и устранение проблемы может предотвратить серьезные поломки и сэкономить вам время и деньги.
				</p>
				<h5 className='font-semibold'>
					Ремонт двигателя: Долговечность и Эффективность
				</h5>
				<p className=''>
					Двигатель - сердце вашего автомобиля. Мы специализируемся на ремонте и техническом обслуживании двигателей различных марок и моделей. Наша цель - вернуть вашему автомобилю полную мощность и эффективность, обеспечивая его долгий срок службы.
				</p>
				<h5 className='font-semibold'>
					Ремонт Подвески: Плавность и Контроль
				</h5>
				<p className=''>
					Подвеска играет важную роль в комфорте и безопасности вашей поездки. Мы производим ремонт подвески с использованием качественных деталей и компетентного подхода, чтобы обеспечить плавность хода и надежность.
				</p>

				<h5 className='font-semibold'>
					Ремонт Тормозной Системы: Ваша Безопасность Превыше Всего
				</h5>
				<p className=''>
					Тормоза - это нечто большее, чем просто педаль. Наши специалисты проведут диагностику и ремонт тормозной системы, чтобы гарантировать вашу безопасность на дорогах.
				</p>
				<h5 className='font-semibold'>
					Ремонт Системы Охлаждения: Надежность в Условиях Повышенных Температур
				</h5>
				<p className=''>
					Система охлаждения - это ваше орудие борьбы с перегревом. Мы устраняем утечки, меняем охлаждающую жидкость и заменяем радиаторы, чтобы ваш автомобиль мог спокойно справляться с жаркими днями.
				</p>
				<h5 className='font-semibold'>
					Шиномонтаж: Ваши Колеса, Ваша Сцепление
				</h5>
				<p className=''>
					Шины - это ваше единственное средство связи с дорогой. Мы предоставляем услуги по шиномонтажу, включая сезонное обслуживание и балансировку, чтобы обеспечить вам максимальное сцепление с дорогой в любых погодных условиях.
				</p>
				<h5 className='font-semibold'>
					Ремонт Электрооборудования: Технология в Движении
				</h5>
				<p className=''>
					Современные автомобили обильно оснащены электроникой. Наши эксперты в области электрооборудования занимаются диагностикой и ремонтом всех электрических компонентов, чтобы ваш автомобиль оставался в топовой форме.
				</p>
				<h5 className='font-semibold'>
					Ремонт Глушителя: Меньше Шума, Больше Экологии
				</h5>
				<p className=''>
					Глушитель не только снижает шум, но и влияет на экологию. Мы ремонтируем и заменяем глушители, чтобы ваш автомобиль был экологичным и тихим.
				</p>
				<h5 className='font-semibold'>
					ТО Авто: Регулярное Обслуживание для Долгой Жизни
				</h5>
				<p className=''>
					Техническое обслуживание (ТО) - это ключ к долговечности вашего автомобиля. Мы предлагаем полный спектр ТО, включая замену масла, фильтров, свечей зажигания и многое другое.
				</p>


				<div className='mt-8'>
					<h6 className='font-semibold text-xl'>
						Наши преимущества:
					</h6>

					<ul className='list-disc pl-3'>
						<li className='mb-1'>
							Профессиональные мастера с богатым опытом.
						</li>
						<li className='mb-1'>
							Современное оборудование и качественные запчасти.
						</li>
						<li className='mb-1'>
							Гарантия на выполненные работы.
						</li>
						<li className='mb-1'>
							Индивидуальный подход к каждому клиенту.
						</li>
						<li className='mb-1'>
							Конкурентные цены.
						</li>
					</ul>
				</div>

				<p className='text-center mt-12 font-bold sd:text-lg xz:text-base'>
					Позаботьтесь о своем автомобиле прямо сейчас! Обратитесь к нам для профессионального обслуживания, и вы получите не только качественные услуги, но и спокойствие на дороге. Не оставляйте свой автомобиль на произвол судьбы. Остановитесь у нас, и мы сделаем все, чтобы ваш автомобиль всегда был в отличной форме. Запишитесь на сервис прямо сейчас!
				</p>

			</div>
		</div>

	)
}

export default ContenPriceSection
import React, { useContext } from 'react'
import { Context } from '../../App'
import { LineOutlined } from '@ant-design/icons'
import img from '../../images/tow_truck.svg'
import img2 from '../../images/evacuator.webp'
import { Image } from 'antd'

export const Evakuator = () => {
	const { data } = useContext(Context)
	return (
		<section id='evakuator' className=''>
		
		</section>
	)
}
